<template>
  <div class="container">
    <Title title="Exercise Physiology" />
    <div class="content">
      <div>
        <p>
          At ProHealth EP we are a team of accredited exercise physiologists who
          are passionate about helping clients improve their holistic health and
          well-being through clinical exercise interventions. We use education
          and individualised exercise therapy to prevent and manage a broad
          range of chronic illness and injury.
        </p>
        <p>
          On your initial consultation we will complete a thorough assessment to
          be able to prescribe the most appropriate, safe and effective exercise
          intervention to achieve your health outcomes.
        </p>
        <p>
          We accept NDIS, Medicare, DVA, WorkCover, Insurance and private
          referrals. Private health fund rebates available *rebates are
          dependent on individual cover.
        </p>
      </div>
      <img
        src="@/assets/running-runner-long-distance-fitness-40751.jpeg"
        alt="Runner"
        id="image"
      />
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 5rem;
}

p {
  line-height: 2em;
}

#image {
  width: 100%;
  @media screen and (min-width: 1280px) {
    width: 40vw;
  }
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>
