<template>
  <div class="container">
    <Title title="Group Programs" />
    <div class="content">
      <h2>BEAT IT Program</h2>
      <p>
        Exercise is strongly recognised as an important component of diabetes
        care. Research has shown that exercise can provide benefits such as
        improving blood glucose control, reducing reliance on medication,
        improving blood lipids, improving blood pressure and quality of life.
      </p>
      <p>
        The BEAT It program was founded by the NDSS and has been developed for
        people who have been diagnosed with or are at risk of developing
        diabetes. The program empowers you with the necessary skills and
        knowledge to assist in the management of your diabetes and chronic
        disease through exercise and lifestyle modifications. Is it designed for
        you to be able to replicate exercises in the home to allow for long-term
        sustainability and lifestyle change.
      </p>
      <p>This 8-week program includes:</p>
      <ul>
        <li>An initial one-on-one assessment with an exercise professional</li>
        <li>Exercise program tailored to your abilities and fitness levels</li>
        <li>16 group exercise sessions (2 per week)</li>
        <li>Tracking your progress</li>
        <li>Final consultation</li>
      </ul>
      <p>
        * This program can be subsidised using the
        <i>Group Allied Health Services for People with Type 2 Diabetes</i>
        referral from your GP.
      </p>
      <p>* Private health fund rebates may be available</p>
      <h2>Osteoporosis &amp; Falls Prevention</h2>
      <p>
        According to the World Health Organisation, 493 Queensland residents
        died due to an unintentional fall, with nearly 95% being ages 65 years
        and over. Falls prevention is an integral part of physical well-being
        which is often overlooked. Muscular strength and power in the lower
        limbs, reaction time, balance and posture impact a persons' falls risk,
        however all of the above can be improved with correctly prescribed
        exercise.
      </p>
      <p>
        Our falls prevention group programs have been developed for people who
        are at risk of falls or who are 65 years or over. The program provides
        the necessary exercise intervention to improve your postural awareness,
        lower limb strength, coordination and reaction time, as well as balance.
      </p>
      <p>* Private health fund rebates may be available</p>
      <h2>Chronic Disease Group Classes</h2>
      <p>
        Chronic diseases are long lasting conditions with persistent effects.
        Their social and economic consequences can impact on peoples' quality of
        life. Chronic conditions are becoming increasingly common and are a
        priority for action in the health sector.
      </p>
      <p>
        Many people with chronic conditions do not have a single, predominant
        condition, but rather they experience multimorbidity—the presence of 2
        or more chronic conditions in a person at the same time.
      </p>
      <p>
        Research has proven that exercise is effective in the management of
        chronic disease and has the ability to reverse, or slow its'
        progression.
      </p>
      <p>
        Our chronic disease group classes are available to those with one or
        more chronic conditions or injuries. The class aims to focus on the
        individual need of the client with personalised exercise programs. Each
        participant will complete an initial assessment before commending the
        group classes and will be prescribed an exercise program tailored to
        their needs and goals.
      </p>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

h2 {
  color: #009cdf;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
}

p {
  line-height: 2em;
}
</style>
