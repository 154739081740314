<template>
  <div class="container">
    <Title title="Hydrotherapy" />
    <div class="content">
      <div>
        <p>
          Hydrotherapy is the delivery of exercise in warm water used to treat
          chronic conditions and maintain health. Is it a great alternative to
          weight bearing exercise for those who suffer from joint pain.
        </p>
        <h2>What are the benefits of hydrotherapy?</h2>
        <ul>
          <li>Aids in muscular relaxation</li>
          <li>Reduced joint stiffness</li>
          <li>Increases range of motion around joints</li>
          <li>Improves blood flow</li>
          <li>Increases strength and endurance</li>
          <li>Improves balance, reduces risk of falls</li>
          <li>Reduces joint pain</li>
        </ul>
        <h2>Who can benefit from hydrotherapy?</h2>
        <p>
          Those who can benefit from hydrotherapy include (but are not limited
          to):
        </p>
        <ul>
          <li>Osteo &amp; Rheumatoid Arthritis</li>
          <li>Low back pain</li>
          <li>Musculoskeletal disorders or injuries</li>
          <li>High falls risk &amp; low levels of balance</li>
          <li>Mental health disorders</li>
          <li>Chronic fatigue and Fibromyalgia</li>
        </ul>
      </div>
      <img
        src="@/assets/swim-water-diving-underwater-52988.jpeg"
        alt="Hydrotherapy"
        id="image"
      />
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

h2 {
  color: #009cdf;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 2rem;
}

p {
  line-height: 2em;
}

#image {
  width: 100%;
  @media screen and (min-width: 1280px) {
    width: 40vw;
  }
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>
