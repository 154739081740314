<template>
  <div class="container">
    <Title title="WorkCover / Return to Work" />
    <div class="content">
      <div>
        <p>
          Our accredited exercise physiologists are registered with WorkCover
          and can assist with your injury rehabilitation treatment through
          clinical exercise-based therapy. We work closely with your case
          manager to provide the appropriate individualised exercise
          interventions to assist you with your injury and work towards full
          functional capacity.
        </p>
        <p>
          We can also assist you with a Return to Work Assessment and report.
          Assessments include a thorough functional and physical fitness
          evaluation to see if you are ready to return to work and complete
          duties required by your employer safely.
        </p>
        <img
          src="@/assets/pexels-photo-3944406.jpeg"
          alt="Return To Work"
          id="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

h1 {
  color: #009cdf;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 5rem;
}

p {
  line-height: 2em;
}

#image {
  width: 100%;
  @media screen and (min-width: 1280px) {
    width: 40vw;
  }
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 3rem;
}
</style>
