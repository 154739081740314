<template>
  <div class="container">
    <Title title="Reformer Pilates" />
    <div class="content">
      <div>
        <h2>What is Reformer Pilates?</h2>
        <p>
          Reformer Pilates focuses on core stability, postural alignment and
          overall strength of the body. All of our instructors are qualified in
          Clinical Pilates, allowing us to safely work with clients who may have
          conditions or injuries.
        </p>
        <p>
          Prior to beginning Reformer Pilates, it is recommended to attend a
          prescreen and assessment. This will ensure that exercise is safe for
          you and will allow us to assess your body's movement and address any
          medical or personal concerns.
        </p>
        <p>We offer a range of classes including:</p>
        <ul>
          <li>Pregnancy Reformer</li>
          <li>Post-Natal Reformer</li>
          <li>Injury Rehabilitation Reformer</li>
          <li>Strength Reformer</li>
          <li>1-1 Reformer</li>
        </ul>
      </div>
      <img src="@/assets/pexels-photo-4498574.jpeg" alt="Pilates" id="image" />
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";

export default {
  components: {
    Title,
  },
};
</script>

<style lang="scss" scoped>
body {
  height: 100vh;
}

h2 {
  color: #009cdf;
}

.content {
  margin: 0em 1em;
  width: 70vw;
  font-family: Inter, Helvetica Neue, Helvetica, Arial, sans-serif;
  margin-left: 4vw;
  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 2rem;
}

p {
  line-height: 2em;
}

#image {
  width: 100%;
  @media screen and (min-width: 1280px) {
    width: 40vw;
  }
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
</style>
